import React from 'react';
import { defineMessages } from 'react-intl';

import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgMessageValues, ArgRenderedIcon, ArgRenderedText } from '../types';
import { renderText } from '../utils/message-descriptor-formatters';
import { ArgButton } from '../arg-button/arg-button';

import './arg-banner.less';

const messages = defineMessages({
    cancel: {
        id: 'basic.arg-banner.cancel.Title',
        defaultMessage: 'Cancel',
    },
    confirm: {
        id: 'basic.arg-banner.confirm.Title',
        defaultMessage: 'Ok',
    },
});

type ArgBannerPlacement = 'top' | 'bottom'

export interface ArgBannerProps {
    /**
     * The placement of the banner on the screen
     */
    placement?: ArgBannerPlacement;

    /**
     * An HTML class for the container
     */
    className?: ClassValue;

    /**
     * The main text or title of the banner
     */
    title: ArgRenderedText;

    /**
     * Values for message interpolation (for internationalization)
     */
    messageValues?: ArgMessageValues;

    /**
     * Hides the confirm button if set to `true`
     */
    hideConfirm?: boolean;

    /**
     * Text for the confirm button.
     */
    confirmText?: ArgRenderedText;

    /**
     * Icon for the confirm button.
     */
    confirmIcon?: ArgRenderedIcon;

    /**
     * Callback function when the confirm button is clicked.
     */
    onConfirm?: () => void;

    /**
     * Text for the cancel button.
     */
    cancelText?: ArgRenderedText;

    /**
     * Icon for the cancel button.
     */
    cancelIcon?: ArgRenderedIcon;

    /**
     * Callback function when the cancel button is clicked.
     */
    onCancel?: () => void;
}

/**
 * This component is designed to display messages with optional action buttons.
 *
 * @example
 * ```
 * <ArgBanner
 *      placement='bottom'
 *      title={messages.importLevelMessage}
 * />
 * ```
 */
export function ArgBanner(props: ArgBannerProps) {
    const {
        className,
        placement = 'top',
        title,
        messageValues,
        cancelText = messages.cancel,
        cancelIcon,
        onCancel,
        hideConfirm,
        confirmText = messages.confirm,
        confirmIcon,
        onConfirm,
    } = props;

    const classNames = useClassNames('arg-banner');

    const cls = {
        top: placement === 'top',
        bottom: placement === 'bottom',
    };

    return (
        <div className={classNames('&', cls, className)} data-testid='arg-banner'>
            <span className={classNames('&-title')}>
                {renderText(title, messageValues)}
            </span>
            <div className={classNames('&-buttons')}>
                <ArgButton
                    type='ghost'
                    icon={cancelIcon}
                    className={classNames('&-buttons-cancel')}
                    label={renderText(cancelText, messageValues)}
                    onClick={onCancel}
                />
                {!hideConfirm && (
                    <ArgButton
                        type='ghost'
                        icon={confirmIcon}
                        className={classNames('&-buttons-confirm')}
                        label={renderText(confirmText, messageValues)}
                        onClick={onConfirm}
                    />
                )}
            </div>
        </div>
    );
}
